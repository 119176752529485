<!--
 * @Description: 设置三级分类弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-18 15:01:04
 * @LastEditTime: 2022-07-01 09:28:50
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="设置三级分类"
    :visible="thirdClassVisible"
    width="800px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    v-loading="dialogLoading"
    :before-close="handleClose"
    custom-class="createDispatchDialog"
  >
    <el-table :data="tableData" height="300px">
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="column.fieldName === 'thirdClassName'">
            <e6-vr-select
              v-model="row.thirdClassId"
              :data="row.thirdList"
              placeholder="商品名称"
              title="商品名称"
              is-title
              clearable
              :props="{
                id: 'thirdClassId',
                label: 'thirdClassName'
              }"
            ></e6-vr-select>
          </span>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit"> 确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getThirdClass, createThirdClass, getThirdClassList } from "@/api";
import { printError } from "@/utils/util";

export default {
  name: "setThirdClassDialog",
  components: {},
  data() {
    return {
      columnData: [
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipType",
          display: true,
          fieldLabel: "设备类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCombo",
          display: true,
          fieldLabel: "设备组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      thirdClassList: [],
      tableData: [], // 表格数据
      dialogLoading: false
    };
  },
  props: ["thirdClassVisible", "itemIdList", "taskId"],
  mixins: [base],
  computed: {},
  watch: {
    thirdClassVisible(val) {
      if (val) {
        this.getThirdClassReq();
      }
    }
  },
  created() {},
  methods: {
    //获取设备明细
    async getThirdClassReq() {
      try {
        this.dialogLoading = true;
        let res = await getThirdClass({ taskId: this.taskId });
        //客户名称下拉框
        let tableData = res.data;
        tableData.map(item => {
          item.thirdClassName = item.thirdClassName ? item.thirdClassName : "";
          item.thirdClassId = item.thirdClassId ? item.thirdClassId : "";
          item.thirdList = [];
        });
        let secondIds = tableData.map(item => {
          return item.secondClassId;
        });
        this.tableData = tableData;
        if (this.tableData.length) {
          this.getThirdClassListReq(secondIds);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取三级分类名称
    async getThirdClassListReq(secondIds) {
      try {
        let res = await getThirdClassList(secondIds);
        let list = res.data;
        let tableData = this.tableData;
        for (let key in list) {
          tableData.map(item => {
            if (item.secondClassId == key) {
              item.thirdList = list[key];
            }
          });
        }
        this.tableData = tableData;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //设置三级分类
    async createThirdClassReq() {
      try {
        this.dialogLoading = true;
        this.tableData.map(item => {
          item.thirdList.forEach(each => {
            if (each.thirdClassId == item.thirdClassId) {
              item.thirdClassName = each.thirdClassName;
            }
          });
        });
        let res = await createThirdClass({
          taskId: this.taskId,
          thirds: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("设置成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = true;
      }
    },
    //点击确定新增
    handleSubmit() {
      if (!this.tableData.every(item => item.thirdClassId)) {
        this.$message.warning("请选择三级分类名称");
        return;
      }
      if (this.tableData.length) {
        this.createThirdClassReq();
      } else {
        this.handleClose();
      }
    },
    //关闭弹框
    handleClose() {
      this.$emit("handleClose", "thirdClassVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding-top: 20px;
  }
}
</style>
