<!--
 * @Description: 新装/加装任务项列表调度信息详情任务项
 * @Author: ChenXueLin
 * @Date: 2021-11-17 17:38:24
 * @LastEditTime: 2023-03-20 17:20:52
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 任务项 start -->
  <div class="task-list-content">
    <detail-table-title>
      <template v-slot:title>
        任务项
      </template>
      <template v-slot:btns>
        <el-button
          style="margin-left:5px;"
          type="primary"
          @click="handleCombination"
          v-if="
            (basicInfo.taskSource == 3 || basicInfo.taskSource == 5) &&
              [1, 3, 6, 7].includes(basicInfo.taskFirstClass)
          "
        >
          维护物料和组合
        </el-button>
        <el-button
          style="margin-left:5px;"
          type="primary"
          @click="setThirdClass"
          v-if="
            basicInfo.taskSource != 3 &&
              basicInfo.taskSource != 5 &&
              $route.meta.operationIds.includes(UNDEFINED_4_BUTTON_ID)
          "
        >
          设置三级分类
        </el-button>
        <el-button
          style="margin-left:5px;"
          type="primary"
          @click="addDispatch"
          v-has="UNDEFINED_5_BUTTON_ID"
          >创建调度任务</el-button
        >
        <el-button
          style="margin-left:5px;"
          type="primary"
          @click="changeEngineer('assgin')"
          v-has="APPROVE_BUTTON_ID"
        >
          指派工程师
        </el-button>
        <el-button
          style="margin-left:5px;"
          type="primary"
          @click="changeEngineer('change')"
          v-has="MAINTENANCE_CHANGE_SAME_CLASS_EQUIPMENT_BUTTON_ID"
        >
          更换工程师
        </el-button>
        <el-button
          type="primary"
          @click="handleItem(1)"
          style="margin-left:5px;"
        >
          撤回任务项
        </el-button>
        <el-button
          type="primary"
          @click="handleItem(2)"
          style="margin-left:5px;"
          v-has="136"
        >
          关闭任务项
        </el-button>
        <el-button
          type="primary"
          @click="handleItem(3)"
          style="margin-left:5px;"
        >
          改约
        </el-button>
      </template>
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="itemIdList">
            <input-plus
              v-model="searchForm.itemIdList"
              valueType="Array"
              placeholder="工单ID"
              title="工单ID"
              label="工单ID"
              clearable
            ></input-plus>
          </el-form-item>
          <el-form-item class="search-item--1" prop="sceneName">
            <el-input
              placeholder="场景名称"
              title="场景名称"
              v-model="searchForm.sceneName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="sceneType">
            <e6-vr-select
              v-model="searchForm.sceneType"
              :data="sceneTypeList"
              placeholder="场景类型"
              title="场景类型"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="engineerrpName">
            <el-input
              placeholder="工程师"
              title="工程师"
              v-model="searchForm.engineerrpName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="engineerrpPhone">
            <el-input
              placeholder="工程师电话"
              title="工程师电话"
              v-model="searchForm.engineerrpPhone"
            ></el-input>
          </el-form-item>

          <el-form-item class="search-item--1" prop="contactName">
            <el-input
              placeholder="安装联系人"
              title="安装联系人"
              v-model="searchForm.contactName"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="contactPhone">
            <el-input
              placeholder="联系人电话"
              title="联系人电话"
              v-model="searchForm.contactPhone"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--1" prop="address">
            <el-input
              placeholder="安装地址"
              title="安装地址"
              v-model="searchForm.address"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </detail-table-title>
    <div class="switch-page-button">
      <el-button
        round
        :class="[clickIndex == index ? 'activeButton' : '']"
        v-for="(btn, index) in btnList"
        :key="index"
        @click="switchPage(index, btn.statusNameCode)"
        >{{ btn.statusName }}{{ btn.count }}</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="340px"
      header-row-class-name="table-header"
      @expand-change="rowExpand"
      :row-key="getRowKeys"
      :expand-row-keys="expands"
      @selection-change="handleSelectionChange"
      ref="multipleTable"
      @select-all="selectAll"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        width="50"
        fixed
      ></el-table-column>
      <!-- 展开行内容 -->
      <el-table-column type="expand">
        <!--  eslint-disable-next-line -->
        <template slot-scope="scope">
          <el-table
            border
            v-loading="childListLoading"
            :data="childTableData"
            header-row-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in expandedColumn"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
            <el-table-column fixed="right" align="center" label="" width="120">
              <!-- <template slot-scope="scope">
                <e6-td-operate
                  :data="getOperateList(scope.row)"
                  @command="handleOperate($event, scope.row)"
                ></e6-td-operate>
              </template> -->
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in newAddColumn"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="120">
        <template slot-scope="scope">
          <e6-td-operate
            :data="getOperateList(scope.row)"
            @command="handleOperate($event, scope.row)"
          ></e6-td-operate>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <!-- 创建调度单弹框   -->
    <create-dispatch
      :basicInfo="basicInfo"
      :createDispatchDialog="createDispatchDialog"
      @handleClose="handleClose"
      :taskId="taskId"
      @refresh="getStatus"
      :itemIdList="itemIdList"
      :itemList="itemList"
      :customerId="customerId"
    ></create-dispatch>
    <!-- 更换/指派工程师 -->
    <change-engineer
      :isChange="isChange"
      :changeType="changeType"
      @refresh="getStatus"
      :basicInfo="basicInfo"
      @handleClose="handleClose"
      :taskId="taskId"
      :customerId="customerId"
      :itemIdList="itemIdList"
      :itemList="itemList"
    ></change-engineer>
    <!-- 选择物料 -->
    <change-material
      @handleClose="handleClose"
      :taskId="taskId"
      :changeMaterialVisible="changeMaterialVisible"
      :isGrayscaleWork="basicInfo.isGrayscaleWork"
      @refresh="refresh"
      :itemIdList="itemIdList"
    ></change-material>
    <!-- 设置三级分类弹框 -->
    <set-third-class-dialog
      @handleClose="handleClose"
      :taskId="taskId"
      :thirdClassVisible="thirdClassVisible"
      @refresh="refresh"
    ></set-third-class-dialog>
    <!-- 改约弹框 -->
    <change-time
      :changeTimeDialog="changeTimeDialog"
      @handleClose="handleClose"
      @updateItemInfo="updateItemInfo"
      :itemIdList="itemIdList"
    ></change-time>
    <!-- 维护物料和组合 -->
    <material-combination
      :materialCombinationVisible="materialCombinationVisible"
      @handleClose="handleClose"
      :isDeliverInstall="basicInfo.isDeliverInstall"
      :taskFirstClass="basicInfo.taskFirstClass"
      :taskId="taskId"
      :taskSource="basicInfo.taskSource"
    ></material-combination>
    <!-- 关闭任务项 -->
    <close-task-item-dialog
      :closeTaskItemDialog="closeTaskItemDialog"
      @handleClose="handleClose"
      dialogType="1"
      @refresh="getStatus"
      :itemIdList="itemIdList"
    ></close-task-item-dialog>
    <!-- 撤回任务项 -->
    <reason-write
      :batchReturnItemDialog="batchReturnItemDialog"
      @handleClose="handleClose"
      @refresh="getStatus"
      :itemIdList="itemIdList"
    ></reason-write>
  </div>
</template>

<script>
import taskDetail from "@/mixins/taskDetail";
import index from "./index.js";
import reasonWrite from "@/components/workOrderManage/reasonWrite.vue";
import inputPlus from "@/components/inputPlus";
import {
  getInstallActionItem,
  installDetail,
  findDownList,
  getStatus
} from "@/api";
import setting from "@/setting";
let {
  UNDEFINED_4_BUTTON_ID,
  UNDEFINED_5_BUTTON_ID,
  APPROVE_BUTTON_ID,
  MAINTENANCE_CHANGE_SAME_CLASS_EQUIPMENT_BUTTON_ID
} = setting;
import { printError } from "@/utils/util";
import DetailTableTitle from "@/components/detailTableTitle.vue";
import { newAddColumn, expandedColumn, addColumn } from "../../tableColumnData";
import createDispatch from "../../components/createDispatch.vue";
import ChangeEngineer from "../../components/changeEngineer.vue";
import setThirdClassDialog from "../../components/setThirdClassDialog.vue";
import changeMaterial from "../../components/changeMaterial.vue";
import changeTime from "@/components/changeTime.vue";
import MaterialCombination from "../../components/materialCombination.vue";
import closeTaskItemDialog from "@/components/workOrderManage/closeTaskItemDialog.vue";
export default {
  name: "newAdd",
  components: {
    closeTaskItemDialog,
    DetailTableTitle,
    createDispatch,
    ChangeEngineer,
    setThirdClassDialog,
    changeMaterial,
    changeTime,
    MaterialCombination,
    reasonWrite,
    inputPlus
  },
  data() {
    return {
      UNDEFINED_4_BUTTON_ID,
      UNDEFINED_5_BUTTON_ID,
      APPROVE_BUTTON_ID,
      MAINTENANCE_CHANGE_SAME_CLASS_EQUIPMENT_BUTTON_ID,
      clickIndex: 0,
      expands: [],
      expandedColumn: expandedColumn,
      tableData: [], //
      searchForm: {
        itemIdList: [], //工单ID
        itemStatus: "", //任务项状态
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        engineerrpName: "", //工程师名称
        engineerrpPhone: "", //工程师电话
        contactPhone: "", //联系人电话
        contactName: "", //联系人名称
        address: "", //安装地址
        pageIndex: 1,
        pageSize: 20
      },
      sceneTypeList: [], //场景类型
      total: 0,
      childTableData: [],
      childListLoading: false,
      //设置三级分类
      thirdClassVisible: false,
      changeMaterialVisible: false
    };
  },
  //任务单ID//客户ID    //页面类型
  props: ["taskId", "customerId", "scheduleInfoId", "basicInfo"],
  mixins: [taskDetail, index],
  computed: {
    newAddColumn() {
      let column = [];
      if (this.basicInfo.taskTypeCode == 1) {
        //新装
        column = newAddColumn;
      } else {
        column = addColumn;
      }
      return column;
    }
  },
  watch: {},
  methods: {
    updateItem() {
      this.getStatus();
    },
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["sceneType"])];
        let [taskTypeRes] = await Promise.all(promiseList);
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
      this.getActionItem();
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      this.getActionItem();
      this.scrollTop = 0;
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getActionItem();
    },
    //点击筛选框
    switchPage(index, itemStatus) {
      this.clickIndex = index;
      this.searchForm.itemStatus = itemStatus;
      this.getActionItem();
      this.itemIdList = [];
      this.itemList = [];
      this.$refs.multipleTable.clearSelection();
    },
    //获取状态列表
    async getStatus() {
      try {
        let res = await getStatus({
          taskId: this.taskId,
          scheduleInfoId: this.scheduleInfoId
        });
        //基本信息
        this.btnList = this.getFreezeResponse(res, {
          path: "data"
        });
        this.searchForm.itemStatus =
          this.btnList && this.btnList.length
            ? this.btnList[0].statusNameCode
            : "";
        this.getActionItem();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取新装加装任务单的任务项列表
    async getActionItem() {
      try {
        let res = await getInstallActionItem({
          taskId: this.taskId,
          scheduleInfoId: this.scheduleInfoId,
          ...this.searchForm
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取安装明细
    async installDetail(itemId) {
      try {
        this.childListLoading = true;
        let res = await installDetail({
          itemId
        });
        //基本信息
        if (res.code == "OK") {
          this.childTableData = res.data;
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout();
          });
          // //res.data;
          // this.childTableData.push({}); //触发vue更新视图
          // this.childTableData.pop(); //把最后添加的空对象删除掉
        }
      } catch (error) {
        printError(error);
      } finally {
        // this.childTableData = [{ thirdClassName: 1111 }];
        this.childListLoading = false;
      }
    },
    getRowKeys: function(row) {
      return row.itemId;
    },
    //点击行
    rowExpand(row, expandedRows) {
      //当行展示时调用接口
      if (expandedRows.length) {
        this.expands = [];
        if (row.itemId) {
          this.expands.push(row.itemId);
          this.childTableData = [];
          this.installDetail(row.itemId);
        }
      } else {
        this.expands = [];
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "任务项详情",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.itemId
          }
        });
      }
    },
    /******选择物料*********/

    changeMaterial() {
      if (!this.basicInfo.isGrayscaleWork && this.itemIdList.length > 1) {
        this.$message.warning("只能勾选一个任务项");
        return;
      }
      if (!this.basicInfo.isGrayscaleWork && !this.itemIdList.length) {
        this.$message.warning("请先勾选一个任务项");
        return;
      }
      this.changeMaterialVisible = true;
    },
    /******设置三级分类**********/
    setThirdClass() {
      this.thirdClassVisible = true;
    },
    refresh() {
      this.expands = [];
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
      this.itemIdList = [];
      this.itemList = [];

      this.$refs.multipleTable.clearSelection();
    }
  },
  created() {
    this.getStatus();
    this.initData();
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-table__expanded-cell {
  padding: 0px;
}
</style>
